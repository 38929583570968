import { ReactNode, createContext, useState } from 'react';
import type { Dispatch, SetStateAction } from 'react';

type CardsAfterDarkContextType = {
  isCardsAfterDark: boolean;
  setCardsAfterDark: Dispatch<SetStateAction<boolean>>;
};

interface MyProviderProps {
  children: ReactNode;
}

export const CardsAfterDarkContext = createContext<
  CardsAfterDarkContextType | undefined
>(undefined);

export default function CardsAfterDark({ children }: MyProviderProps) {
  const [isCardsAfterDark, setCardsAfterDark] = useState<boolean>(false);
  return (
    <CardsAfterDarkContext.Provider
      value={{ isCardsAfterDark, setCardsAfterDark }}
    >
      {children}
    </CardsAfterDarkContext.Provider>
  );
}
