var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"a442d7ae4092f9ba66a8609717c9c084a09c069a"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

if (process.env.NODE_ENV !== 'development') {
  // percentage of sessions reported to Sentry <> fallback to 20%
  const tracesSampleRate =
    parseFloat(process.env.NEXT_PUBLIC_WEB_CLIENT_SENTRY_TRACES_SAMPLE_RATE) ??
    0.2;
  // percentage of replay sessions logged <> fallback to 5% due to marginal utility
  const replaysSessionSampleRate =
    parseFloat(
      process.env.NEXT_PUBLIC_WEB_CLIENT_SENTRY_REPLAYS_SESSION_SAMPLE_RATE
    ) ?? 0.05;
  // percentage of replay sessions (w/error) logged <> fallback to 100% to maximize traceability
  const replaysOnErrorSampleRate =
    parseFloat(
      process.env.NEXT_PUBLIC_WEB_CLIENT_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE
    ) ?? 1.0;

  Sentry.init({
    dsn: process.env.NEXT_PUBLIC_WEB_CLIENT_SENTRY_DSN,

    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate,

    replaysSessionSampleRate,
    replaysOnErrorSampleRate,

    // You can remove this option if you're not planning to use the Sentry Session Replay feature:
    integrations: [
      new Sentry.Replay({
        // Additional Replay configuration goes in here, for example:
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
  });
}
