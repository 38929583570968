import { useEffect } from 'react';

/**
 * Dynamically load the mock service worker (MSW) if the NEXT_PUBLIC_USE_MSW_MOCKS env var is defined instead of the backend API in the browser for client-side data fetching
 */
export function useMSWClient() {
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_USE_MSW_MOCKS) {
      (async () => {
        try {
          const { mswWorker } = await import('@inkd/graphql/mocks');
          mswWorker.start({
            onUnhandledRequest: 'bypass',
          });
        } catch (_err) {
          console.warn("Couldn't load mock service worker.");
        }
      })();
    }
  }, []);
}
