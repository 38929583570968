// tailwind styles
import '@inkd/config/tailwind.css';

import type { AppProps } from 'next/app';
import { ApolloProvider } from '@apollo/client';
import { apolloClient, useMSWClient } from '@/libs';
import CardsAfterDarkContext from '../context/cardsAfterDark';

export default function App({ Component, pageProps }: AppProps) {
  useMSWClient();

  return (
    <ApolloProvider client={apolloClient}>
      <CardsAfterDarkContext>
        <Component {...pageProps} />
      </CardsAfterDarkContext>
    </ApolloProvider>
  );
}
